import React from 'react';
import { useStaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout"
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss'

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulHomeSlide(sort: {fields: orderId, order: ASC}) {
        edges {
          node {
            companyLink
            linkNamePro
            companyImg {
              fluid(maxWidth: 1800, quality: 50) {
                sizes
                src
                aspectRatio
                srcSet
                srcSetWebp
                srcWebp
                
              }
            }
            blur
            textUp
            textBottom
            addButtons
            link1
            nameLink1
            link2
            nameLink2
          }
        }
      }
    }
  `)

  const params = {
    speed: 1400,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
    },
  }

  return (
    <Layout color="white">
      <Swiper {...params}>
        {data.allContentfulHomeSlide.edges.map((edge, i) => {
          let blurValue = "" + edge.node.blur;
          let blur = "blur(" + blurValue + "px)"
          return (
            <div className="bg-wrapper" key={i}>
              <BackgroundImage className={edge.node.blur === 0 ? "companyImg" : "companyImg blur"} fluid={edge.node.companyImg.fluid} style={{
                filter: blur,
                transform: `scale(1.1)`,
              }}>
              </BackgroundImage>
              <div className="slide-main-info">
                <h1 className="text-up">{edge.node.textUp}</h1>
                <h1 className="text-bot">{edge.node.textBottom}</h1>
                {edge.node.addButtons &&
                  <div className="home-buttons">
                    <a href={edge.node.link1}><span>{edge.node.nameLink1}</span></a>
                    <a href={edge.node.link2}><span>{edge.node.nameLink2}</span></a>
                  </div>
                }
              </div>
              <div className="slide-info">
                {edge.node.linkNamePro && <a className="cta-slide" href={edge.node.companyLink} target="_blank" rel="noreferrer">{edge.node.linkNamePro}</a>}
              </div>
              <div className="progress-bar"></div>
            </div>
          )
        }
        )}
      </Swiper>
    </Layout>
  )
}

export default IndexPage
